import moon from "@/assets/images/moon.svg";
import sun from "@/assets/images/sun.svg";
import Image from "next/image";
import React from "react";
import { layoutModeTypes } from "../constants/layout";

function LightDark({ layoutMode, onChangeLayoutMode }) {
  const mode =
    layoutMode === layoutModeTypes["DARKMODE"]
      ? layoutModeTypes["LIGHTMODE"]
      : layoutModeTypes["DARKMODE"];

  const handleChangeTheme = () => {
    onChangeLayoutMode(mode);
    localStorage.setItem("ThemeMode", mode);
  };

  return (
    <label className="theme2 js-theme">
      <input
        className="theme2__input"
        id="checkbox"
        type="checkbox"
        onClick={handleChangeTheme}
      />
      <Image
        width={25}
        height={25}
        loading="lazy"
        className="some-icon"
        src={moon}
        alt="moon-icon"
      />
      <Image
        width={25}
        height={25}
        loading="lazy"
        className="some-icon-dark"
        src={sun}
        alt="sun-icon"
      />
    </label>
  );
}

export default React.memo(LightDark);
